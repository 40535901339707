import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { VModal, VModalButton } from '../VModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";

type DialogContextType = {
    openDialog: (config: DialogConfig) => void
}
const DefaultDialogConfig:DialogConfig = {
    title: '',
    body:'',
    actionCallback: (v:boolean) => {},
    bodyClassName: '',
}
const DialogContext = React.createContext<DialogContextType|null>(null);

export const DialogProvider:React.FC<PropsWithChildren> = ({children}) => {
    const [confirmBusy, setConfirmBusy] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [dialogConfig, setDialogConfig] = React.useState<DialogConfig>(DefaultDialogConfig);
    const openDialog = useCallback( (config: DialogConfig ) =>
    {
        setConfirmBusy(false); // in case we stack dialogs too zealously
        
        setShowModal(true);
        setDialogConfig({
            ...config
        });
    },[]);
    const resetDialog = () => {
        setShowModal(false);
        setDialogConfig(DefaultDialogConfig);
      };
    const onConfirm = async () => {
        if(dialogConfig.onPositiveClick)
        {
            setConfirmBusy(true);
            let result = await dialogConfig.onPositiveClick();
            setConfirmBusy(false);
            if(!result)
                return;
        }
        resetDialog();
        dialogConfig.actionCallback(true);
      };
    
      const onDismiss = useCallback( () => {
        resetDialog();
        dialogConfig.actionCallback(false);
      },[dialogConfig]);

      let bodyJsx = <div>
          {dialogConfig.body}
          {/* <br/>
          <label><input type="checkbox" /> Do not show this warning again</label> */}
      </div>
      
    const dialogContext = useMemo(() => {
        return { openDialog }
    },[openDialog])

    const cancelButtonOption = useMemo(() => {
        if(dialogConfig.hideNegativeAction)
            return null;
        return {
                negativeAction: {
                disabled: confirmBusy,
                label:"Cancel",
                onClick: onDismiss
            }
        }
    },[confirmBusy, onDismiss, dialogConfig.hideNegativeAction]);
    return (<DialogContext.Provider value={dialogContext}>
        <VModal 
            // size="sm"
            size={dialogConfig.size}
            onHide={() => { }}
            show={showModal}
            body={bodyJsx}            
            positiveAction={{
                label:dialogConfig.ok ?? "Ok",
                onClick: onConfirm,
                busy:confirmBusy,
                type: dialogConfig.okType,
                customColorScheme: dialogConfig.okCustomColorScheme
            }}
            {...cancelButtonOption}
            header={dialogConfig.title}
            bodyClassName={dialogConfig.bodyClassName}
        />
        {children}
    </DialogContext.Provider>);    
}
type DialogConfig = {
    title: string|React.ReactNode,
    body: React.ReactNode,
    ok?:string
    okType?: VModalButton['type'],
    okCustomColorScheme?: VModalButton['customColorScheme'] //todo: make this just a vModalButton obj, 
    actionCallback:(confirmed:boolean) => void
    bodyClassName?: string
    onPositiveClick?: () => Promise<boolean>,
    hideNegativeAction?: boolean,
    size?:"sm"|"medium"|"lg"
}

const useDialogueContext = () =>
{
    const ctx = React.useContext(DialogContext);
    if(!ctx)
        throw new Error('Expecting DialogContext, not found - using DialogProvider?');
    return ctx;
}
export const useConfirmationDialog = () => {
    const { openDialog } = useDialogueContext();
  
    const getConfirmation = React.useCallback( (options:Omit<DialogConfig,'actionCallback'>):Promise<boolean> => {
        return new Promise((res) => {
            openDialog( { ...options, actionCallback:res });
        });
    },[openDialog]);
    const warningPopup = React.useCallback( (options:Omit<DialogConfig,'actionCallback'>):Promise<boolean> => {
        return new Promise((res) => {
            let {title, ...restOptions} = options;
            let titleJsx = <>
                <FontAwesomeIcon icon={faExclamationCircle} color="red"/>
                &nbsp;
                {title}    
            </>
            openDialog( { title:titleJsx, ...restOptions, actionCallback:res, hideNegativeAction:true });
        });
    },[openDialog]);

    const confirmCancelChoices = React.useCallback(async <T extends string>(params:{
            inputName: string,
            title:string,
            body?:React.ReactNode,
            defaultValue: T,
            options: {
                label: string,
                value: T,    
            }[]
        }) =>
        {
        
            let value = params.defaultValue;
            
            const editTypeChange:React.ChangeEventHandler<HTMLInputElement> = (e) => {
                if(e.currentTarget.checked)
                value = e.currentTarget.value as T;
            }
        
            let editTypeJsx = <div>
                {params.body && <div>{params.body}</div>}
                {params.options.map(opt => (
                <div className="radio" key={opt.value}>
                    <label>
                        <input 
                            type="radio" 
                            name={params.inputName} 
                            value={opt.value} 
                            defaultChecked={opt.value === params.defaultValue} 
                            onChange={editTypeChange}
                        /> 
                        {opt.label}
                    </label>
                </div>))}
            </div>;
        
            const status = await getConfirmation({
                title: params.title,
                body: editTypeJsx,
                ok: 'Ok',
                okType: 'primary',
                size:"sm"
                // okCustomColorScheme: type === 'draft' ? 'draft' : undefined
            });
        
            return { status, value }
        },[getConfirmation]);


    // const getConfirmationWithActions = (options:DialogConfig):Promise<boolean> =>
    //   new Promise((res) => {
    //     openDialog( { ...options, actionCallback:res });
    //   });
    return { getConfirmation, warningPopup, confirmCancelChoices };
  };
