
import React, { useCallback, useState } from 'react';
import { VFormTextInput } from '../../components/core/forms/VFormTextInput';
import { FormProvider, useForm } from 'react-hook-form';
import { VPanel } from '../../components/core/VPanel';
import { VForm, VFormErrorProvider, VFormErrors } from '../../components/core/forms/VForm';
import { VeriClockLogo } from '../../components/brand/VeriClockLogo';
import { VBetterButton } from '../../components/core';
import { ajvResolver } from '../../../../../lib/src/validation/AjvReactHookFormResolver';
import { CommonObjectSchema } from '../../../../../lib/src/validation/SchemaCentral';
import { VeriClockValidationError, isApiValidationError, makeValidationError, makeValidationErrorResponse } from '../../../../../vericlock_api/src/validation/ApiValidationErrorResponse';
import { serverYupValidationResponseToReactHookFormErrors } from '../../../libs/ApiYupHelpers';
import { useVeriClockApi } from '../../../../../sharedReact/src/hooks/ApiProviderCommon';
import { ApiPublicAuthenticationResponse } from '../../../../../vericlock_api/src/types/Authentication';
import { useLocation } from 'react-router-dom';

type LoginInfoType = {
    username: string,
    password: string
}
const authSchema = {
    ...CommonObjectSchema,
    required:["username", "password"],
    properties: {
        username: {
            oneOf: [{
                    $ref: "http://www.vericlock.com/schema/common.json#/definitions/EmailAddress",
                },
                {
                    type: "string", //must match \d+\*\d+/
                    pattern: '^\\d+\\*\\d+$',
                    transform: ['trim']
                },
                {
                    type: "string", //must match integer:<string valid domain characters>
                    pattern: '^\\d+:[a-zA-Z0-9_\\-]+$',
                    transform: ['trim']
                }
            ],
            // type: "string",
            // minLength: 1,
            errorMessage: "A valid email is required"
        },
        password: {
            type: "string",
            minLength: 1,
            errorMessage: "Password is required"
        }
    }
}

function extraAuthValidation(data:LoginInfoType)
{
    const errors:VeriClockValidationError[] = [];
    if(data.username.length < 3)
    {
        errors.push(makeValidationError({
            path: 'username',
            message: 'Email must be at least 3 characters',
            type: 'minLength'
        }));
    }

    if(errors.length > 0)
    {
        throw makeValidationErrorResponse(errors);
    }
}

const LoginContainerStyle:React.CSSProperties = {
    maxWidth: "500px", 
    width: "100%", 
    margin: "0 auto"
}

export function LoginToAccountList(props: {
    loginAccounts: {
        companyName: string
        domain: string
        fullDomain: string
        loginUrl?: string
        userFriendlyName?: string
    }[]
    onSelectAccount?: (account: typeof props.loginAccounts[0]) => void //this overrides the url behavior
    headerJsx?: React.ReactNode
    footer?: {
        jsx: React.ReactNode,
        onClick: () => void
    }
})
{
    const { loginAccounts, headerJsx, footer, onSelectAccount } = props;

    const onAccountPicked = useCallback((e:React.MouseEvent<HTMLButtonElement>) => {
        if(e.currentTarget.dataset.accountIndex === undefined)
            throw new Error('Account index not set on button');
        const accountIndex = parseInt(e.currentTarget.dataset.accountIndex)
        if(!loginAccounts)
            throw new Error('No login accounts set');
        const account = loginAccounts[accountIndex];

        if(onSelectAccount)
            return onSelectAccount(account); //
        if(account.loginUrl)
            window.location.href = account.loginUrl;
        else
            alert('Not possible to login to this account - contact support'); //unlikely a real possibility

    },[loginAccounts,onSelectAccount]);

    return <div style={LoginContainerStyle}>            
        <ul className="list-group">
            {headerJsx && <button className="list-group-item disabled" disabled={true}>
               {headerJsx}
            </button>}
            {loginAccounts.map((account, index) => {
                return <button className="list-group-item" key={index} onClick={onAccountPicked} data-account-index={index}>
                    <b>{account.companyName}</b><br/>
                    {account.userFriendlyName ? account.userFriendlyName : account.domain}<br/>
                    <small><i>https://{account.fullDomain}</i></small>
                </button>
            })}
            {footer && <button className="list-group-item" onClick={footer.onClick}>
                {footer.jsx}
            </button>}
        </ul>
    </div>
}
export function PublicLoginForm()
{
    const loc = useLocation();
    //get url params
    const urlParams = new URLSearchParams(loc.search);
    const setupBlob = urlParams.get('setup'); //passed from the redirec from the completed open id session

    const { api } = useVeriClockApi();
    const formMethods = useForm<LoginInfoType>({
        defaultValues: {
            username: '',
            password: ''
        },
        reValidateMode: 'onSubmit',
        criteriaMode: 'all', //needed?,
        resolver: ajvResolver(authSchema)
    });
    const { username } = formMethods.watch();
    const [loginAccounts,setLoginAccounts] = useState<ApiPublicAuthenticationResponse['orgs'][0][] | null>(null);

    const { setError } = formMethods;
    const onSubmit = useCallback(async (data:LoginInfoType) => {
        try {
            extraAuthValidation(data);
            let authResponse = await api.authenticatePublic({
                user: data.username, 
                password: data.password,
                setupBlob: setupBlob ? setupBlob : undefined,
                loginUrlRequested: true
            });
            
            if(authResponse.payload.orgs.length === 0)
            {
                throw new Error('Unexpected auth but not accounts associated with your user info');   
            }

            //only 1 account, we can just redirect
            if(authResponse.payload.orgs.length === 1 && authResponse.payload.orgs[0].loginUrl)
            {
                window.location.href = authResponse.payload.orgs[0].loginUrl;
            }
            else
            {
                setLoginAccounts(authResponse.payload.orgs);
            }
        } catch (err) {
            if(isApiValidationError(err))
                serverYupValidationResponseToReactHookFormErrors(err, setError);
            else {
                const errMsg = err.message ? err.message : err.toString();
                ///@ts-expect-error -- using the /unknown path to inject an error that won't normally be handled by the form, thus displayed in the last ditch location - ts-expect error because this SHOULD cause a ts error on the line since the path doesn't exist
                setError('/unknown',{ type: 'unknown',message: errMsg });
            }
        }

    },[api, setError, setupBlob]);

    if(loginAccounts)
    {
        return <LoginToAccountList 
            loginAccounts={loginAccounts} 
            headerJsx={<>Your email, {username}, is associated with multiple VeriClock accounts. Choose the account you wish to login to.</>}
        />
    }
    return <FormProvider {...formMethods}>
        <VForm onSubmit={formMethods.handleSubmit(onSubmit)}>
            <VFormErrorProvider>
                {/* <div style={{display:"flex", justifyContent:"center", flexDirection: "row"}}>
                    <div style={{display:"flex", justifyContent:"center", flexDirection: "column", width: "100%"}}> */}
                        <div style={LoginContainerStyle}> 
                            <VPanel footer={<VBetterButton type="submit">Continue</VBetterButton>}>
                                <div style={{marginBottom: "1em"}}>
                                    <VeriClockLogo />
                                </div>

                                <VFormTextInput fieldLabel="Email" fieldName="username" />
                                <VFormTextInput fieldLabel="Password" fieldName="password" fieldIsPassword={true}/>
                                <VFormErrors>{(errors) => <div style={{color:"red"}}>{errors}</div>}</VFormErrors>
            
                            </VPanel>
                        </div>
                    {/* </div>
                </div> */}
            </VFormErrorProvider>
        </VForm>
    </FormProvider>
}