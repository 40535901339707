import React, { PropsWithChildren, useState } from 'react';
import { ApiGuid, isApiGuid, Job, JobBudgetInfo, JobBudgetTypeInfo, AllJobBudgets } from '../../../../../vericlock_api/src/types';

import {
    Link,
    Navigate,
    useNavigate,
    useLocation,
    useParams
  } from "react-router-dom";
import { useJob, useJobBudgetInfo } from '../../../hooks/JobHooks';
import { VButton, VModal, VTable, VTableColumn } from '../../components/core';
import { Glyphicon } from 'react-bootstrap';

const baseUrl = '/members/jobs';

type JobInfoModalProps = {
    show:boolean,   
    closeEditor: () => void,
    jobGuid: ApiGuid//|undefined|null,
    showBudgets:boolean,
    showDescription:boolean
    showAncestry:boolean
}
const JobInfoLabel:React.FC<PropsWithChildren> = ({children}) =>
{
    return <div><label>{children}</label></div>
}
const JobInfoBlock:React.FC<PropsWithChildren> = ({children}) =>
{
    return <div style={{...InfoStyle, paddingBottom:"5px"}}>{children}</div>
}

const MissingWageDataModal:React.FC<{
    jobBudgetInfo:JobBudgetInfo
    show:boolean,
    onHide:() => void
}> = ({jobBudgetInfo,show,onHide}) => {

    const missingTimeTypeToPayrollItem = jobBudgetInfo.missingPayrollItemsByTimeType.length > 0;
    const missingWageDataInPayrollItem = jobBudgetInfo.missingRatesByPayrollItem.length > 0;

    type MissingDataRow = { //common style between two - employee,name and comma separated list of time types, or payroll items
        employeeName: string,
        employeeGuid: ApiGuid,
        dataString: string
    }
    const missingTimeTypeColumns:VTableColumn<MissingDataRow>[] = [
        {
            header: "Employee",   
            component: (props) => <Link to={`/members/employees/edit/${props.row.row.employeeGuid}`}>{props.row.row.employeeName}</Link>,           
            style: {width: "50%"}
        },
        {
            header: "Time Types w/o Rule",
            accessor: "dataString",
            style: {width: "50%"}
        }
    ]
    const missingTimeTypeRowData:MissingDataRow[] = jobBudgetInfo.missingPayrollItemsByTimeType.map(missing => {
        const row:MissingDataRow = {
            employeeName: missing.employeeFullName,
            employeeGuid: missing.employeeGuid,
            dataString: missing.timeTypes.join(',')
        }
        return row;
    })
    const missingTimeTypeJsx = missingTimeTypeToPayrollItem && <div>
        We could not find a payroll item for the following employee/wage type combinations. 
        This is likely because of a missing or misconfigured payroll item rule. 
        Please check your <a href="/members/payrollItems/rules">payroll item rules</a> and make sure that 
        the time types are mapped to an appropriate payroll item for these employees in all cases.
        <VTable columns={missingTimeTypeColumns} data={missingTimeTypeRowData} />
    </div>

const missingWageColumns:VTableColumn<MissingDataRow>[] = [
    {
        header: "Employee",   
        component: (props) => <Link to={`/members/employees/edit/${props.row.row.employeeGuid}#payroll`}>{props.row.row.employeeName}</Link>,           
        style: {width: "50%"}
    },
    {
        header: "Payroll Items Missing Wage",
        accessor: "dataString",
        style: {width: "50%"}
    }
]
const missingWageRowData:MissingDataRow[] = jobBudgetInfo.missingRatesByPayrollItem.map(missing => {
    const row:MissingDataRow = {
        employeeName: missing.employeeFullName,
        employeeGuid: missing.employeeGuid,
        dataString: missing.payrollItems.join(',')
    }
    return row;
})
const missingWageJsx = missingWageDataInPayrollItem && <div>
    The following employees are missing wage data for the listed payroll items. 
    To fix, jump to the employee's payroll settings by clicking the employee's name.
    <VTable columns={missingWageColumns} data={missingWageRowData} />
</div>

    const bodyJsx = <>
        {missingTimeTypeJsx}
        {missingWageJsx}
    </>

    return <VModal 
        header="Missing Wage Data"
        body={bodyJsx}
        show={show}
        onHide={onHide}
        positiveAction={{
            label:"Ok",
            onClick: onHide
        }}
    />
} 
const budgetTypeLabelStyle:React.CSSProperties = {
    float: "left",
}
const budgetNumbersLabelStyle:React.CSSProperties = {
    float: "right",
}
const jobBudgetProgressWrapperStyle:React.CSSProperties = {
    height:"34px",
    marginBottom:"0px"
}
function budgetIsNumber(val:any):val is number
{
    return typeof(val) === 'number';
}
const ProgressBarJobBudget:React.FC<{
    value: number,
    budget: number|null|undefined,
    labelLeft: string,
    jobBudgetInfo: JobBudgetInfo
}> = ({value, budget, labelLeft, jobBudgetInfo}) => {

    //under budget, percent => value / budget
    //over budget, percent => value / (budget + overAmount), + overAmount / (budget + overAmount)    
    let progressBarJsx:React.ReactNode|null;
    const showBudget = budgetIsNumber(budget);
    if(budgetIsNumber(budget))
    {
        const overBudgetAmount = value - budget;
        const isOverBudget = value > budget;

        //progress bars are normalized to 1 - 100;
        //if we're over budget, we need to divide the progress area between full budget and over budget amount
        const progressPercent = 
            isOverBudget ? (budget == 0 ? 50 : Math.round(100 * budget / (budget + overBudgetAmount))) :
                (budget == 0 ? 100 : Math.round(100 * value/budget))
        
        const overBudgetPercent = budget === 0 ? 50 : Math.round(100 * overBudgetAmount / (budget + overBudgetAmount));

        const primaryProgressStyle:React.CSSProperties = {
            width: `${progressPercent}%`
        }
        const overageProgressStyle:React.CSSProperties = {
            width: `${overBudgetPercent}%`
        }
        const overBudgetLabelStyle:React.CSSProperties = {
            float: 'left',
            fontSize:"12px",
            color:"red",
            margin: "7px 4px"
        }
        const overBudgetJsx = isOverBudget && <>
            <span className="glyphicon glyphicon-exclamation-sign" style={overBudgetLabelStyle}></span>
            <small style={{...overBudgetLabelStyle, margin:"4px 4px"}}>Overbudget</small>
        </>;

        progressBarJsx = <div style={jobBudgetProgressWrapperStyle} className="progress">
            <div className="progress-bar progress-bar-success progress-bar-striped active" style={primaryProgressStyle}>
                {isOverBudget && overBudgetJsx}
            </div>
                {/* <div class="progress-bar progress-bar-warning progress-bar-striped active" style="width: 0%"></div> */}
            {isOverBudget && <div className="progress-bar progress-bar-danger progress-bar-striped active" style={overageProgressStyle}></div>}
            </div>
    }
    else //no budget set
    {
        progressBarJsx = <div style={jobBudgetProgressWrapperStyle} className="progress form-control-static">
            <div className="progress-bar progress-bar-info" role="progressbar" style={{width: "100%"}}>
                Not Set
            </div>
        </div>
    }

    return <div>
        <div>
            <div style={budgetTypeLabelStyle}><label>{labelLeft}</label></div>
            {showBudget && <div style={budgetNumbersLabelStyle}><label>{value.toFixed(2)} / {budget}</label></div>}
            <div style={{clear:"both"}}></div>
        </div>
        <div>
            {progressBarJsx}
        </div>        
    </div>
}

function WarningGlyph()
{
    const warningGlyphStyle:React.CSSProperties = {
        color: "red"
    }
    return <span className="glyphicon glyphicon-exclamation-sign" style={warningGlyphStyle}></span>
} 

const JobBudgetProgress:React.FC<{    
    budgetDollars:number|undefined|null,
    budgetHours:number|undefined|null,
    budgetTypeInfo: JobBudgetTypeInfo,
    jobBudgetInfo:JobBudgetInfo}> = ({budgetDollars, budgetHours, jobBudgetInfo, ...props}) => {
/*
active?: boolean;
        bsSize?: Sizes;
        bsStyle?: string;
        bsClass?: string;
        interpolatedClass?: any; // TODO: Add more specific type
        max?: number;
        min?: number;
        now?: number;
        srOnly?: boolean;
        striped?: boolean;
        label?: React.ReactNode;
*/
    const [showMissingWageData, setShowMissingWageData] = useState(false);

    // const dollarsOverBudget = job[props.budgetInfo.dollars] !== null ? props.jobBudgetInfo.totalDollars - job[props.budgetInfo.dollars] : null;
    // const hoursOverBudget = job[props.budgetInfo.hours] !== null ? props.jobBudgetInfo.totalHours - job[props.budgetInfo.hours] : null;
    
    
    
    const timeGuardJsx = jobBudgetInfo.hasTimeGuard ? <div>
        <WarningGlyph />&nbsp;One or more shifts in this budget time range were timeguarded
    </div> : null;
    const warningBlockStyle:React.CSSProperties = {
        marginLeft:"2em"
    }
    const isMissingWageData = (typeof(budgetDollars) === 'number') &&
                            ((jobBudgetInfo.missingRatesByPayrollItem && jobBudgetInfo.missingRatesByPayrollItem.length > 0) || 
                            (jobBudgetInfo.missingPayrollItemsByTimeType && Object.keys(jobBudgetInfo.missingPayrollItemsByTimeType).length > 0));

    const wageMissingJsx = (isMissingWageData) ?  
        <>
            <WarningGlyph />&nbsp;<a onClick={() => setShowMissingWageData(true)}>One of more shifts in the time range has missing wage data</a>
            <MissingWageDataModal jobBudgetInfo={jobBudgetInfo} show={showMissingWageData} onHide={() => setShowMissingWageData(false)} />
        </> : null;

    const hasWarnings = isMissingWageData || jobBudgetInfo.hasTimeGuard;
    return <>
        <ProgressBarJobBudget value={jobBudgetInfo.totalDollars} budget={budgetDollars} labelLeft="Dollars" jobBudgetInfo={jobBudgetInfo}/>
        <ProgressBarJobBudget value={jobBudgetInfo.totalHours} budget={budgetHours} labelLeft="Hours" jobBudgetInfo={jobBudgetInfo}/>
        {hasWarnings && <div style={warningBlockStyle}>
            {timeGuardJsx}
            {wageMissingJsx}
        </div>}
    </>
}
export const JobBudgetBlock:React.FC<{
    jobGuid: ApiGuid, 
    budgetDollars:number|undefined|null,
    budgetHours:number|undefined|null,
    budgetTypeInfo: JobBudgetTypeInfo}> = (props) => {
    
    const [triggerLoad, setTriggerLoad] = useState(false);
    const { jobBudgetInfo, isLoading, error } = useJobBudgetInfo(props.jobGuid, props.budgetTypeInfo.range, triggerLoad);

    const budgetDollars = props.budgetDollars;
    const budgetHours = props.budgetHours;

    const noBudgetDefined = (!budgetDollars && !budgetHours)    

    return <>
        <JobInfoLabel>Budget: {props.budgetTypeInfo.label}</JobInfoLabel>
    {noBudgetDefined && 
        <JobInfoBlock>N/A</JobInfoBlock>}
    {((budgetHours && budgetHours > 0) || (budgetDollars && budgetDollars > 0)) && 
        <JobInfoBlock>
            {error && <div className="alert alert-danger">{error}</div>}
            {!error && !jobBudgetInfo && <VButton busy={isLoading} onClick={() => setTriggerLoad(true)}>Load Progress</VButton>}
            {!error && !isLoading && jobBudgetInfo && <JobBudgetProgress 
                budgetDollars={budgetDollars} 
                budgetHours={budgetHours} budgetTypeInfo={props.budgetTypeInfo} jobBudgetInfo={jobBudgetInfo} />}
        </JobInfoBlock>}
    </>

}

function JobName({jobGuid, bold}:{jobGuid:ApiGuid, bold?:boolean})
{
    const { job } = useJob(jobGuid);
    if(job)
    {
        const name = `${job.code} - ${job.name}`;
        if(bold)
            return <b>{name}</b>
        else 
           return <>{name}</>
    }
    return null;
}
const JobParentTree:React.FC<PropsWithChildren<{jobGuid:ApiGuid, prefix?:JSX.Element, under?:JSX.Element[], first?:boolean}>> = ({children, jobGuid, under=[], first=true}) => 
{
    const { job, isLoading, loadError } = useJob(jobGuid);
    if(isLoading)
        return null; 
    if(loadError)
        return <>N/A</>
    if(!job)
        return <>Not Found</>  //not possible for a real job

    if(job.parentGuid)
        return <><JobParentTree jobGuid={job.parentGuid} under={[<JobName jobGuid={job.guid} bold={first}/>,...under]} first={false}/></>

    let underRender = under?.map( (u,i) => <div key={i} style={{paddingLeft:`${(i+1)}em`}}><Glyphicon glyph="arrow-right" />&nbsp;{u}</div>);
    return <><JobName jobGuid={job.guid}/>{underRender}</>
}

///@ts-expect-error - not explicitly targeting 2018 yet
const TagRegex = /(<address>.*?<\/address>|<url>.*?<\/url>)/igms;
///@ts-expect-error - not explicitly targeting 2018 yet
const AddressRegex = /<address>(.*)<\/address>/is;
///@ts-expect-error - not explicitly targeting 2018 yet
const UrlRegex = /<url>(.*)<\/url>/is;

function AddressUrlFilter(props: {
    text: string
})
{
    const bracketTags = '__bortybarf__';
    const replaceTag = "ReplaceAndSplitTag";
    const replaceUniqueTag = bracketTags + replaceTag + bracketTags;
    let replaceIndex = 0;
    let replacerJsx:JSX.Element[] = [];
    let newStr = props.text.replace(TagRegex, function(match, p1, offset)
    {
        let matches = match.match(AddressRegex)
        if(matches && matches.length >= 2)
        {
            const str = matches[1].trim();
            if(str.length > 0)
            {
                const address = str;    
                const madeUrl = 'https://www.google.com/maps?q=' + encodeURI(address);            
                replacerJsx.push(<a key={'str_replace_' +replaceIndex} href={madeUrl} target="_blank">{address}</a>)
                replaceIndex++;
                return replaceUniqueTag;
            }
        }
        else {
            matches = match.match(UrlRegex);
            if(matches && matches.length >= 2)
            {
                const str = matches[1].trim();
                if(str.length > 0)
                {
                    const madeUrl = str.indexOf('http') === 0 ? str : 'http://' + str; //add http prefix if not there
                    replacerJsx.push(<a key={'str_replace_' +replaceIndex} href={madeUrl} target="_blank">{madeUrl}</a>)
                    replaceIndex++;
                    return replaceUniqueTag;
                }
            }
        }

        console.warn('could not match address/url in: ' + match);
        return match; //leave the same
    });

    let textFragments:(string|JSX.Element)[] = newStr.split(bracketTags);

    let nextToReplaceIndex = 0;

    for(let i=0; i < textFragments.length; i++)
    {
        let block = textFragments[i];
        if(block === replaceTag)
        {
            textFragments[i] = replacerJsx[nextToReplaceIndex];
            nextToReplaceIndex++;
        }
    }

    return <>{textFragments}</>
}


const InfoStyle:React.CSSProperties = {
    whiteSpace:'pre-wrap',
    wordBreak: 'break-word'
}
function JobInfoContent({
    job,
    showBudgets,
    showDescription,
    showAncestry
}:{
    showBudgets:boolean
    showDescription:boolean
    showAncestry:boolean
    job:Job
})
{
    return <div>
            <JobInfoLabel>Job</JobInfoLabel>
            <JobInfoBlock>{job.code} - {job.name}</JobInfoBlock>

        {job.parentGuid && showAncestry && <>
            <JobInfoLabel>Parent Job</JobInfoLabel>
			<JobInfoBlock><JobParentTree jobGuid={job.guid} /></JobInfoBlock>
        </>}
        {showDescription && <>
            <JobInfoLabel>Description</JobInfoLabel>
            <JobInfoBlock>{job.description ? <AddressUrlFilter text={job.description} /> : <i>None</i>}</JobInfoBlock>
        </>}

        {showBudgets && AllJobBudgets.map( (budget,index) => <JobBudgetBlock key={index} 
            jobGuid={job.guid} 
            budgetDollars={job[budget.dollars]}
            budgetHours={job[budget.hours]}
            budgetTypeInfo={budget} />)}

        </div>
}

export function JobInfoModal(props:JobInfoModalProps)
{
    const { job, loadError, isLoading } = useJob(props.jobGuid);
    const closeEditor = () => {
        //could optionally check form for changes, and warn
        props.closeEditor(); 
    }

    const title = 'Job Info';
    const bodyJsx = loadError ?  
        <div className="alert alert-danger">
            There was a problem loading the job, please try again in a few moments<br/>
            {loadError}
        </div> 
        : (!isLoading && !loadError && job) ? 
            <JobInfoContent 
                job={job} 
                showBudgets={props.showBudgets}
                showDescription={props.showDescription}
                showAncestry={props.showAncestry}
            /> 
            : <div className="alert alert-danger">Job not found</div>

    return <VModal 
        header={title}
        isLoading={isLoading}
        show={props.show}
        body={bodyJsx}
        onHide={closeEditor}
        positiveAction={{
            label: 'Ok',
            onClick: closeEditor
        }}
    />
}

type JobEditFormProps = {
    // job: Job | null 
}
function JobInfoModalLauncher(props:JobEditFormProps)
{
    // const location = useLocation<undefined|{background?:Location[]}>();
    const location = useLocation();
    const { jobGuid } = useParams<{jobGuid?:ApiGuid}>();
    const [redirectOutOfHere, setRedirectOutOfHere] = useState(false);
    const [showModal] = useState(true);
    const nav = useNavigate();

    const closeModalForm = () => {

        if(location.state?.background)
            nav(-1); //.goBack();
        else //navigated as intrapage model, we leave the way we came in...back
            setRedirectOutOfHere(true); //signal to get out of here
        //todo - we could use the job type to navigate to the active/inactive/deleted page accordingly
        //add job would redirect to active?
    }

    if(!(jobGuid && isApiGuid(jobGuid)) || redirectOutOfHere)
        return <Navigate to={baseUrl + '/active'} />

    return <JobInfoModal 
        show={showModal} 
        closeEditor={closeModalForm}
        jobGuid={jobGuid}
        //for now, if you can nav here, you will have the data (off chance nav here incorrectly, no data will exist anyways)
        showBudgets={true}
        showDescription={true}
        showAncestry={true}
    />

    // return <div>Job Editor<br/>
    //     {isEdit ? <div>Editing job:{jobGuid}</div> : <div>New Job</div>}
    // </div>
}

export default JobInfoModalLauncher;