import React, { forwardRef, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { TabItem, TabMenu } from '../../components/core/menus/TabNavigation';
import { VPanel, VPanelBody, VPanelHeader } from '../../components/core/VPanel';
import { useAllEmployeesClockStatus } from '../../../hooks/ClockStatusHooks';
import { ItemLabelList, VBetterButton, VCheckbox, VLoadingIndicator } from '../../components/core';
import { useEmployeeList } from '../../../hooks/EmployeeHooks';
import { useGroupList } from '../../../hooks/GroupHooks';
import { combineIsLoading, combineLoadErrors } from '../../../../../sharedReact/src/hooks/queryHookHelpers';
import { ApiGuid, Employee } from '../../../../../vericlock_api/src/types';
import { customLabelCallback, renderEmployee, renderJob } from '../../../../../sharedReact/src/hooks/PickerRenderSortHelpers';
import { usePermissions, useV } from '../../../hooks/V';
import { useCompanyLoaded } from '../../../hooks/companyHooks';
import { useJobList } from '../../../hooks/JobHooks';
import { VLazyComponent } from '../../components/core/VLazyComponent';
import { getEmployeeGroupGuidsFromEmployee } from '../../../../../lib/src/helpers/employeeHelpers';
import { ClockResult } from '../../../../../sharedReact/src/hooks/PunchClockHooks';

const baseUrl = '/members/clockinout';

const WebPunchClock = React.lazy(() => import(/* webpackChunkName: "WebPunchClock" */'./WebPunchClock'));
const LazyWebPunchClock = (props:React.ComponentProps<typeof WebPunchClock>) => { return <VLazyComponent><WebPunchClock {...props} /></VLazyComponent> }


const useCombinedRefs = (...refs:any): React.MutableRefObject<any> => {
    const targetRef = React.useRef();
  
    React.useEffect(() => {
      refs.forEach((ref:any) => {
        if (!ref) return;
  
        if (typeof ref === 'function') {
          ref(targetRef.current);
        } else {
          ref.current = targetRef.current;
        }
      });
    }, [refs]);
  
    return targetRef;
  };


// type OurIndeterminateProps = {
//     indeterminate?: boolean;
// } &  React.HTMLAttributes<HTMLInputElement>

interface OurIndeterminateProps extends React.HTMLProps<HTMLInputElement> {
    indeterminate?: boolean;
}
export const OurIndeterminateCheckbox = forwardRef<OurIndeterminateProps,OurIndeterminateProps>(
    ({ indeterminate, ...rest }, ref: React.Ref<OurIndeterminateProps>) => {        
        const defaultRef = React.useRef(null);
        const combinedRef = useCombinedRefs(ref, defaultRef);
    
        useEffect(() => {
          if (combinedRef?.current) {
            combinedRef.current.indeterminate = indeterminate ?? false;
          }
    }, [combinedRef, indeterminate]);
    
    return <input type="checkbox" ref={combinedRef} {...rest} />
});

function Divider()
{
    //soft gray dashed divider line
    return <div style={{borderTop: '1px dashed #ccc', margin: '10px 0'}}></div>
}

function GroupInOutList({employeeGuids, type}:{type:'in'|'out', employeeGuids: ApiGuid[]})
{
    const label = type === 'in' ? 'Group Clock In:' : 'Group Clock Out:';
    return <div>
        <b>{label}</b>&nbsp;
        <ItemLabelList limit={null} employeeGuids={employeeGuids}/>
    </div>
}  

type GroupPageState = 'selectUsers'|'clockIn'|'clockOut';
function GroupClockInOut()
{
    const [pageState, setPageState] = useState<GroupPageState>('selectUsers');
    const [selectedEmployeeMap, setSelectedEmployeeMap] = useState<Record<ApiGuid, true>>({});
    const employeeGuids = Object.keys(selectedEmployeeMap);

    const { getEmployeeStatusByGuid } = useAllEmployeesClockStatus();
    
    const showGroupClockInOutButtons = true;
    const [groupClockInEnabled, setGroupClockInEnabled] = useState(false);
    const [groupClockOutEnabled, setGroupClockOutEnabled] = useState(false);

    const onChangeSelectedEmployees = useCallback((newSelected:Record<ApiGuid, true>) => {
        let clockedInUsersPicked = false;
        let clockedOutUsersPicked = false;
        const selectedEmployeeGuids = Object.keys(newSelected);

        //better - make the picker not say false, but delete!!!
        for(let i=0; i < selectedEmployeeGuids.length; i++)
        {
            const eeGuid = selectedEmployeeGuids[i];
            const status = getEmployeeStatusByGuid(eeGuid);
            if(status?.status === 'clockedIn')
            {
                clockedInUsersPicked = true;
            }
            else if(!status || status?.status === 'clockedOut')
            {
                clockedOutUsersPicked = true;
            }
            if(clockedInUsersPicked && clockedOutUsersPicked)
            {
                break;
            }
        }
        
        //if clocked out users are selected, enable clock in button
        setGroupClockInEnabled(clockedOutUsersPicked);
        //if clocked in users are selected, enable clock out button
        setGroupClockOutEnabled(clockedInUsersPicked);
        setSelectedEmployeeMap(newSelected);
    },[getEmployeeStatusByGuid]);

    const onClickGroupClockIn = useCallback(() => {
        setPageState('clockIn'); 
    },[]);
    const onClickGroupClockOut = useCallback(() => {
        setPageState('clockOut');
    },[]);
    const onClockSuccess = useCallback((result:ClockResult) => {
        onChangeSelectedEmployees({}); //call our change handler, and it should reset the buttons + other relevent state
        setPageState('selectUsers');
    },[onChangeSelectedEmployees]);

    if(pageState === 'clockIn' || pageState === 'clockOut')
    {
        const direction= pageState === 'clockIn' ? 'in' : 'out';
        //filter out employees that are not actually with the correct status (in or out) for the direction (out / in)
        const filteredEmployeeGuids = employeeGuids.filter(eeGuid => getEmployeeStatusByGuid(eeGuid)?.status === (direction === 'in' ? 'clockedOut' : 'clockedIn'));
        return <div>
            <div style={{padding: "10px" }}>
                <VBetterButton onClick={() => setPageState('selectUsers')}>Back to user selection</VBetterButton>
            </div>
            <VPanelBody>
                <GroupInOutList type={direction} employeeGuids={filteredEmployeeGuids} />
                <Divider />
                <LazyWebPunchClock type="raw" includeActivityNotes={true} groupClock={{
                    direction: direction,
                    employeeGuids:filteredEmployeeGuids,
                }}
                onSuccess={onClockSuccess}
            />
            </VPanelBody>
        </div>
    }
    else if(pageState === 'selectUsers')
    {
        return <>
            {showGroupClockInOutButtons && <div style={{padding: "10px" }}>
                    <VBetterButton disabled={!groupClockInEnabled} onClick={onClickGroupClockIn}>Clock In</VBetterButton>
                    &nbsp;
                    <VBetterButton disabled={!groupClockOutEnabled} onClick={onClickGroupClockOut}>Clock Out</VBetterButton>
            </div>}
            <EmployeeGroupPicker value={selectedEmployeeMap} onChange={onChangeSelectedEmployees} />
        </>
    }
    else {
        throw new Error('Invalid page state [534534]');
    }
}

function getIndeterminateSelectionType(employees:Employee[], selectedMap:Record<ApiGuid|'none', boolean>)
{
    let checkCount = 0;
    for(let e=0; e < employees.length; e++)
    {
        const ee = employees[e];
        if(selectedMap[ee.guid]) 
        {
            checkCount++;                    
        }                
    }  
    return checkCount === employees.length ? true : checkCount === 0 ? false : 'indeterminate'; 
}
function EmployeeGroupPicker({
    value:selectedEmployeeMap,
    onChange,
}:{
    value: Record<ApiGuid, true>,
    onChange: (value:Record<ApiGuid, true>) => void
})
{
    const currentEmployee = useV().getUser()
    
    const company = useCompanyLoaded();
    const eeStatuses = useAllEmployeesClockStatus();
    const groupList = useGroupList({statusToFetch: ['active']});
    const eeList = useEmployeeList({statusToFetch: ['active']});
    const jobList = useJobList({statusToFetch: ['active','inactive']});
    const useAdditionalGroups = company.company.settings.multiGroupEnabled;
    const groupGuidToEmployeeList = useMemo(() => {
        const groupToEmployeeList:Record<ApiGuid|'none', Employee[]> = {}
        
        eeList.employees.forEach(ee => {
            const groupGuids = getEmployeeGroupGuidsFromEmployee(useAdditionalGroups, ee);
            if(groupGuids.length > 0)
            {
                groupGuids.forEach(groupGuid => {
                    if(!groupToEmployeeList[groupGuid])
                        groupToEmployeeList[groupGuid] = [];
                    groupToEmployeeList[groupGuid].push(ee);
                });
            }
            else //ee in no groups, put in the none bucket
            {
                if(!groupToEmployeeList['none'])
                    groupToEmployeeList['none'] = [];
                groupToEmployeeList['none'].push(ee);        
            }
        })    
        return groupToEmployeeList;
    },[eeList.employees, useAdditionalGroups]);

    // const jobList = useEmployeeList({statusToFetch: ['active','inactive']});
    const listOfHooks = [eeStatuses,groupList,eeList,company,jobList];
    const isLoading = combineIsLoading(listOfHooks);
    const loadError = combineLoadErrors(listOfHooks);

    const getEmployeeByGuid = eeList.getByGuid;
    const notFoundLabel = '[Deleted Employee]';
    
    const renderListCustomizeEmployee = React.useCallback((eeGuid:ApiGuid) => {
        return customLabelCallback({
            viewingEmployeeGuid:currentEmployee.guid,
            itemGuid:eeGuid,
            getByGuid: getEmployeeByGuid,
            getEmployeeByGuid: getEmployeeByGuid, 
            companyVisualCustomizations: company.company?.settings.visualCustomizations,
            notFoundLabel,
            type: 'employee',
            render: renderEmployee
        });
    }, [currentEmployee.guid, getEmployeeByGuid,company.company?.settings.visualCustomizations,notFoundLabel]);

    const { getByGuid: getJobByGuid } = jobList;

    const renderListCustomizeJob = React.useCallback((jobGuid:ApiGuid) => {
        return customLabelCallback({
            viewingEmployeeGuid:currentEmployee.guid,
            itemGuid:jobGuid,
            getByGuid: getJobByGuid,
            getEmployeeByGuid: getEmployeeByGuid, 
            companyVisualCustomizations: company.company?.settings.visualCustomizations,
            notFoundLabel: '[Deleted Job]',
            type: 'job',
            render: renderJob
        });
    }, [currentEmployee.guid,getJobByGuid,getEmployeeByGuid,company.company?.settings.visualCustomizations]);


    const getGroupByGuid = groupList.getByGuid;
    // const group list 
    const groupGuidList = useMemo(() => {
        const guidList = Object.keys(groupGuidToEmployeeList);

        //sort according to our sort needs here maybe?
        guidList.sort((guidA, guidB) => {
            const groupA = getGroupByGuid(guidA);
            const groupB = getGroupByGuid(guidB);
            const nameA = groupA?.name ?? 'Ω';
            const nameB = groupB?.name ?? 'Ω';
            return nameA.toLowerCase().localeCompare(nameB.toLowerCase());
        });

        return guidList;
    },[groupGuidToEmployeeList, getGroupByGuid]);

    // const onGroupClicked:MouseEventHandler<HTMLLIElement> = (e) => {
    const onGroupClicked:MouseEventHandler<HTMLInputElement> = (e) => {
        const n = e.currentTarget.attributes.getNamedItem('data-group-guid');
        const groupGuid = n?.value;
        if(groupGuid)
        {
            const eeListInGroup = groupGuidToEmployeeList[groupGuid];  
            const currentState = getIndeterminateSelectionType(eeListInGroup,selectedEmployeeMap);
            let newCheckedState = true;
            if(currentState === true || currentState === 'indeterminate')
                newCheckedState = false; //turn off;
            
            let newState = {...selectedEmployeeMap};
            eeListInGroup.forEach(ee => {
                if(newCheckedState)
                    newState[ee.guid] = true;
                else
                    delete newState[ee.guid];
            });
            onChange(newState);
        }
    }
    const onEmployeeClicked:Parameters<typeof VCheckbox>[0]['toggleChecked'] = (checked, toggleData:ApiGuid) => {
        const newState = {...selectedEmployeeMap};
        if(checked)
            newState[toggleData] = true;
        else
            delete newState[toggleData];
        onChange(newState);
    }

    
    const selectedGroupMap = useMemo(() => {

        let map:Record<ApiGuid|'none', boolean|'indeterminate'> = {};

        for(let i=0; i < groupGuidList.length; i++)
        {
            const groupGuid = groupGuidList[i];
            // const group = getGroupByGuid(groupGuid);
            const eeListInGroup = groupGuidToEmployeeList[groupGuid];  
            map[groupGuid] = getIndeterminateSelectionType(eeListInGroup,selectedEmployeeMap);           
        }
        return map;
    },[selectedEmployeeMap,groupGuidToEmployeeList,groupGuidList]);

    const groupListJsx: JSX.Element[] = [];
    groupGuidList.forEach((groupGuid, idx) => {
        const eeListInGroup = groupGuidToEmployeeList[groupGuid];
        const group = getGroupByGuid(groupGuid);
        
        groupListJsx.push(<li className="list-group-item checkableListGroupItem" key={groupGuid} style={{backgroundColor:"#F0F0F0"}}>
                {/* <IndeterminateCheckbox checked={selectedGroupMap[group.guid]} toggleChecked={onGroupClicked} toggleData={group.guid}> */}
                <div className="checkbox">
                    <label>
                        <OurIndeterminateCheckbox 
                            checked={selectedGroupMap[groupGuid] === true} 
                            indeterminate={selectedGroupMap[groupGuid] === 'indeterminate'} 
                            onChange={onGroupClicked}
                            data-group-guid={groupGuid}
                        />
                        {' '}
                        {groupGuid === 'none' ? 'Ungrouped Employees' : (group?.name ?? '[Deleted Group]')}
                    </label>
                {/* </VCheckbox> */}
                </div>
            </li>);

        if(eeListInGroup && eeListInGroup.length)
        {
            eeListInGroup.forEach(ee => {
                const status = eeStatuses.getEmployeeStatusByGuid(ee.guid);
                const statusJsx = (!status || status.status === 'clockedOut')
                    ? <div style={{color: 'gray'}}>Clocked Out</div> 
                    : <div style={{color: 'green'}}>{status.jobGuid ? renderListCustomizeJob(status.jobGuid) + ' - ' : ''}Clocked In</div>
                const jsx = <li className="list-group-item checkableListGroupItem" key={ee.guid}>
                    <VCheckbox checked={selectedEmployeeMap[ee.guid] === true} toggleChecked={onEmployeeClicked} toggleData={ee.guid}>
                        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>
                                {renderListCustomizeEmployee(ee.guid)}
                            </div>
                            <div>
                                {statusJsx}
                            </div>
                        </div>
                    </VCheckbox>
                </li>
                groupListJsx.push(jsx);
            });
        }
        else {
            groupListJsx.push(<li className="list-group-item" key={groupGuid + '_none'}><i>No employees in group</i></li>);
        }
    });


    if(isLoading)
        return <VLoadingIndicator />
    if(loadError)
        return <div className="alert alert-danger">{loadError.map((e,i) => <div key={i}>{e}</div>)}</div>

    return <ul className="list-group">
        {groupListJsx.length > 0 ? groupListJsx : <li className="list-group-item"><div className="alert alert-warn">No groups managed</div></li>}        
    </ul>
}
function SingleClockInOut()
{
    return <VPanelBody>
        <LazyWebPunchClock type="raw" includeActivityNotes={true} />
    </VPanelBody>
}

declare var __is_mobile_backend_template:boolean|undefined;
// export
export default function PunchClockPage()
{
    // const location = useLocation<undefined|{background:Location[]}>();
    const location = useLocation();
    // let nextBackground:Location[] = [location]
    // if(location.state?.background)
    //     nextBackground = location.state?.background.concat(location);

    const { hasPermission } = usePermissions();
    const userCanGroupClock = hasPermission('can_group_web_clock_own_group');

    //is there a background ?  If so, don't render the menu section, just route
    let background = location.state && location.state.background;

    const isMobileTemplate = typeof(__is_mobile_backend_template) !== 'undefined' && __is_mobile_backend_template;
    //use __is_mobile_backend_template to hide the main panel when rendered in the mobile view, as here is a separate menu currently
    return <div className="centerPage">
        <VPanel passThroughChildrenOnly={!!background || isMobileTemplate} childrenInPanelBody={false}>
            {(!background && !isMobileTemplate) && <VPanelHeader>
                <TabMenu type="pills" renderHint='inPanelHeader'>
                    <TabItem to={baseUrl + '/'}>My Clock In/Out</TabItem>
                    {userCanGroupClock && <TabItem to={baseUrl + '/group'} alternativeUrls={[baseUrl+'/group/in', baseUrl+'/group/out']}>Group Clock In/Out</TabItem>}
                </TabMenu>
            </VPanelHeader>}
            
            <Routes>
                {userCanGroupClock && <Route path={'group'} element={<GroupClockInOut />} />}
                {userCanGroupClock && <Route path={'groupClockInOut'} element={<Navigate to={baseUrl + '/group'} />} />}
                {/* {userCanGroupClock && <Route path={'group/in'} element={<GroupClockIn />} />}
                {userCanGroupClock && <Route path={'group/out'} element={<GroupClockOut />} />} */}
                {/* <Route path={'personal'} element={<SingleClockInOut />} /> */}
                {/* redirects for old URLs */}
                <Route path="" element={<SingleClockInOut />} />
                <Route path={'*'} element={<Navigate to={baseUrl + '/'} replace />} />
            </Routes>
            
        </VPanel>
    </div>

}