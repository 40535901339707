import { useCallback } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useVeriClockApi } from '../../../sharedReact/src/hooks/ApiProvider';
import { ReactQueryCommonUseQueryOptions, ReactQueryExtractErrorString, VQueryKey } from '../../../sharedReact/src/hooks/queryHookHelpers';
import { ApiGuid, ClockInRequest, ClockOutRequest, EmployeeClockStatus, EmployeeGuidToClockStatus, GroupClockInRequest, GroupClockOutRequest } from '../../../vericlock_api/src/types';

const commonUseQueryOptionsCompany:UseQueryOptions<EmployeeGuidToClockStatus> = {
    ...ReactQueryCommonUseQueryOptions,
    staleTime: 5*60000, //stale after 5 minutes - though, need to rethink - the SPA will keep it in sync unless the server changes...and for that we solve another way (though this is a light weight query so sending it off is not bad)
    refetchOnWindowFocus: true, //freshens data after leavin the window (less chance of stale data)
    refetchOnMount: true, //refetch if stale - see infinity staleTime
    refetchOnReconnect: false, //unsure
}

export function useAllEmployeesClockStatus(options?:{
    enabled?:boolean,
    employeeGuids?: ApiGuid[],  //non == all
})
{
    const {
        employeeGuids,
        enabled=true,
    } = (options ?? {});

    const { api } = useVeriClockApi();
    const queryClient = useQueryClient();
    const query = useQuery<EmployeeGuidToClockStatus>([VQueryKey.EmployeeClockState, employeeGuids ], async () => {
        const resp = await api.getEmployeeClockStatus({
            employeeGuids
        });
        return resp.payload.employeeStatus;
     },{ 
        ...commonUseQueryOptionsCompany,
        enabled
    });

    //reactquery/or other method to refetch every so often?  Or just do it manually?
    // useEffect(() => {
     //ohelper.refetchEmployeeStatuses();
    // },[]);

    const { data, isLoading } = query;
    const { errorString: loadError } = ReactQueryExtractErrorString(VQueryKey.EmployeeClockState, query);
    
    const getEmployeeStatusByGuid = useCallback( (employeeGuid:ApiGuid) => {
        if(data)
        {
            if(data[employeeGuid])
                return data[employeeGuid];
            return null; //loaded but not found
        }
        return undefined; //not loaded yet
    },[data]);

    const updateCache = useCallback((employeeGuid: ApiGuid, clockStatus:EmployeeClockStatus) => {
        //query client will update the cache for each possible list of queries
        const blobs = queryClient.getQueriesData<EmployeeGuidToClockStatus>(VQueryKey.EmployeeClockState);
        blobs.forEach(([qKey, data]) => {
            if(data[employeeGuid])
            {
                //employee is in the query data, replace it and set it
                const newData = {...data, [employeeGuid]: clockStatus};
                queryClient.setQueryData(qKey, newData);
            }
        });
    },[queryClient]);

    const groupClockEmployeeIn = useCallback(async (params:GroupClockInRequest) => {
        const resp = await api.groupClockEmployeeIn(params);
        const payload = resp.payload;
        if(payload.result === 'success')
        {
            payload.clockStatus.forEach((status) => {
                updateCache(status.employeeGuid, status);
            });
        }
        return payload;
    },[api,updateCache]);
    const groupClockEmployeeOut = useCallback(async (params:GroupClockOutRequest) => {
        const resp = await api.groupClockEmployeeOut(params);
        const payload = resp.payload;
        if(payload.result === 'success')
        {
            payload.clockStatus.forEach((status) => {
                updateCache(status.employeeGuid, status);
            });
        }
        return payload;
    },[api,updateCache]);

    const clockEmployeeIn = useCallback(async (params:ClockInRequest) => {
        
        try {
            const resp = await api.clockEmployeeIn(params);
            const payload = resp.payload;
            if(payload.result === 'success')
            {
                updateCache(payload.clockStatus[0].employeeGuid, payload.clockStatus[0]);                
            }
            return payload;
        } catch (err) {
            if(err.code === 'AlreadyClockedOutError' || err.code === 'AlreadyClockedInError')
            {
                queryClient.invalidateQueries(VQueryKey.EmployeeClockState); //purge them all - could get more clever, but simpler
            }
            throw err;
        }
    },[api,updateCache,queryClient]);
    
    const clockEmployeeOut = useCallback(async (params:ClockOutRequest) => {
        
        try {
            const resp = await api.clockEmployeeOut(params);
            const payload = resp.payload;
            if(payload.result === 'success')
            {
                updateCache(payload.clockStatus[0].employeeGuid, payload.clockStatus[0]);                
            }
            // else if(payload.result === 'error')
            // {
                
            // }
            return payload;
        } catch (err) {
            if(err.code === 'AlreadyClockedOutError' || err.code === 'AlreadyClockedInError')
            {
                queryClient.invalidateQueries(VQueryKey.EmployeeClockState); //purge them all - could get more clever, but simpler
            }
            throw err;
        }
    },[api,updateCache,queryClient]);

    return {
        employeeStatus: data,
        loadError,
        isLoading,
        getEmployeeStatusByGuid,
        clockEmployeeIn,
        clockEmployeeOut,
        groupClockEmployeeIn,
        groupClockEmployeeOut
    }
}

// export function useKiosk
export function useEmployeeClockStatus(employeeGuid:ApiGuid)
{
    const employeeGuids = employeeGuid ? [employeeGuid] : undefined;
    const { isLoading, loadError, getEmployeeStatusByGuid, clockEmployeeIn, clockEmployeeOut, groupClockEmployeeIn, groupClockEmployeeOut } = useAllEmployeesClockStatus({
        enabled: !!employeeGuid,
        employeeGuids
    })
    
    if(!employeeGuid)
        return {
            isLoading: false,
            loadError: "employeeGuid is undefined", //in practice, we shoudl either not trigger this, or not be monitoring the error in this case,
            employeeStatus: undefined,
        };

    return {
        isLoading: isLoading,
        loadError: loadError,
        employeeStatus: getEmployeeStatusByGuid(employeeGuid),
        clockEmployeeIn,
        clockEmployeeOut,
        groupClockEmployeeIn,
        groupClockEmployeeOut
    }
}