import React, { PropsWithChildren } from 'react'
import {
    useLocation,
    Link,
    LinkProps,
  } from "react-router-dom";


const TabMenuStyle:React.CSSProperties = {marginBottom:"1em"}
export const TabMenu:React.FC<{type?:'pills'|'tabs', renderHint?:'inPanelHeader'} & PropsWithChildren> = ({type='tabs', renderHint, children}) =>
{
    //if pills go in a panel header, les remove that padding
    const menuStyle = renderHint === 'inPanelHeader' ? undefined : TabMenuStyle;
    const className = type === 'tabs' ? 'nav nav-tabs' : 'nav nav-pills';
    return <ul className={className} style={menuStyle}>
        {children}
    </ul>
}

function isUrlInLinkProps(url:string, linkProps:Omit<LinkProps&TabItemProps,"alternativeUrls">, alternativeUrls: undefined|string[])
{
    //url needs to be exactly the url with optional trailing /
    let testCurrentUrl = (url[url.length-1] === '/') ? url : url + '/'; //add trailing / if not present

    if(Array.isArray(linkProps.to))
    {
        for(const url of linkProps.to)
        {
            const toUrl = (url[url] === '/') ? url : url + '/'; //add trailing / if not present
            if(testCurrentUrl === toUrl)
            {
                return true;
            }
        }
    }
    else if(typeof(linkProps.to) === 'string')
    {
        const toUrl = (linkProps.to[linkProps.to.length-1] === '/') ? linkProps.to : linkProps.to + '/'; //add trailing / if not present
        if(testCurrentUrl === toUrl)
        {
            return true;
        }
    }

    if(alternativeUrls)
    {
        for(const altUrl of alternativeUrls)
        {
            const toUrl = (altUrl[altUrl.length-1] === '/') ? altUrl : altUrl + '/'; //add trailing / if not present
            if(testCurrentUrl === toUrl)
            {
                return true;
            }
        }
    }
    return false; //really shouldn't go this way
}

type TabItemProps = { 
    alternativeUrls?: string[]  //alt urls that can land on this tab
}
type DropDownProps = {
    links: (LinkProps & {
        linkLabel: string|JSX.Element        
    })[]
}
function isDropDownProps(props:LinkProps|DropDownProps):props is DropDownProps
{
    return Array.isArray((props as any).links);
}
export const TabItem:React.FC<PropsWithChildren<(LinkProps|DropDownProps) & TabItemProps>> = (props) => {
    const location = useLocation();
    const currentUrl = location.pathname;
    const {children, alternativeUrls, ...restProps } = props;

    if(isDropDownProps(restProps))
    {
        const links = restProps.links;
        // restProps.links[0].to

        let hasActiveUnderDropdown = false;

        const linkJsx = links.map( (link,i) => {
            const {linkLabel, ...linkProps} = link;
            let linkClass = '';
            let isActive = isUrlInLinkProps(currentUrl, linkProps, alternativeUrls);
            // let isActive = (Array.isArray(linkProps.to) && linkProps.to.findIndex(l => currentUrl.indexOf(l) === 0))
            //     || (typeof(linkProps.to) === 'string' && currentUrl.indexOf(linkProps.to) === 0);

            if(isActive)
            { 
                hasActiveUnderDropdown = true;
                linkClass = 'active';
            }            
            return <li key={i} className={linkClass}>
                <Link {...linkProps}>{linkLabel}</Link>
            </li>
        });
        return <ul className={"nav nav-pills" }>
        <li role="presentation" className={"dropdown" + (hasActiveUnderDropdown ? ' active' : '')}>
          <a className="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            {children} <span className="caret"></span>
          </a>
          <ul className="dropdown-menu">
            {linkJsx}
          </ul>
        </li>
      </ul>
    }
    else {

        //if te current url starts with the url in the 'to' props, we're on an active branch
        let isActive = isUrlInLinkProps(currentUrl, restProps, alternativeUrls);

        // let isActive = (Array.isArray(restProps.to) && restProps.to.findIndex(l => currentUrl.indexOf(l) === 0))
        //     || (typeof(restProps.to) === 'string' && currentUrl.indexOf(restProps.to) === 0);
        
        const className = isActive ? "active" : "";
        //could look at https://reactrouter.com/web/api/NavLink for styling help 

        return <li className={className}>
            <Link {...restProps}>{children}</Link>
        </li>
    }
}