//import this as soon as possible
import { TranslationProvider } from './TranslationProvider';

import React, { useState, CSSProperties, PropsWithChildren, ReactNode, useCallback, useMemo, useContext, useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    useLocation,
    Navigate,
  } from "react-router-dom";

import { VLazyComponent } from '../ui/components/core/VLazyComponent';
import { BrowserApiClient } from "../../../vericlock_api/src/BrowserApiClient";
import { AppContextProvider } from "./AppContextProvider";
import { PublicLoginForm } from "../ui/pages/Authentication/LoginPages";

const EmployeesMenuTab = React.lazy(() => import(/* webpackChunkName: "EmployeesMenuTab" */'../ui/pages/Employees/EmployeesMenuTab'));
const SchedulingSettingsPage = React.lazy(() => import(/* webpackChunkName: "SchedulingSettingsPage" */'../ui/pages/Scheduling/SchedulingSettingsPage'));
const NotificationsPage = React.lazy(() => import(/* webpackChunkName: "NotificationsPage" */'../ui/pages/Notifications/NotificationsPage'));
const EmailBroadcastPage = React.lazy(() => import(/* webpackChunkName: "EmailBroadcastPage" */'../ui/pages/Notifications/EmailBroadcastPage'));
const LazyEmailBroadcast = () => <VLazyComponent><EmailBroadcastPage /></VLazyComponent>
const TimeClassifierSettingsPage = React.lazy(() => import(/* webpackChunkName: "TimeClassifierSettingsPage" */'../ui/pages/TimeClassifierSettingsPage'));
const PtoMenuTab = React.lazy(() => import(/* webpackChunkName: "PtoMenu" */'../ui/pages/Pto/PtoMenuTab'));
const NewAccountWelcome = React.lazy(() => import(/* webpackChunkName: "NewAccountWelcome" */'../ui/pages/Onboarding/NewAccountWelcome'));
const OvertimeTestPage = React.lazy(() => import(/* webpackChunkName: "OvertimeTestPage" */'../ui/pages/OvertimeTestPage/OvertimeTestPage'));
const ScheduledReportsList = React.lazy(() => import(/* webpackChunkName: "ScheduledReportsList" */'../ui/pages/ScheduledReportEditor/ScheduledReportEditor'));
const CustomReportsList = React.lazy(() => import(/* webpackChunkName: "CustomReportsList" */'../ui/pages/CustomReports/CustomReportsList'));

import JobMenuTab from '../ui/pages/Jobs/JobMenuTab';
import { QueryClient, QueryClientProvider } from "react-query";
import { DialogProvider } from "../ui/components/core/VDialog";

const ServiceItemSettingsMenu = React.lazy(() => import(/* webpackChunkName: "ServiceItemSettingsMenu" */'../ui/pages/ServiceItems/ServiceItemSettingsMenu'));
const PayrollItemSettingsMenu = React.lazy(() => import(/* webpackChunkName: "PayrollItemSettingsMenu" */'../ui/pages/PayrollItems/PayrollItemSettingsMenu'));
const ContactsPage = React.lazy(() => import(/* webpackChunkName: "ContactsPage" */'../ui/pages/ContactsPage/Contacts'));

const PayrollSettingsMenu = React.lazy(() => import(/* webpackChunkName: "PayrollSettingsMenu" */'../ui/pages/Settings/PayrollSettingsMenu'));
const TimeAdjustmentSettingsMenu = React.lazy(() => import(/* webpackChunkName: "TimeAdjustmentSettingsMenu" */'../ui/pages/Settings/TimeAdjustmentSettingsMenu'));
const TimeSettingsMenu = React.lazy(() => import(/* webpackChunkName: "TimeSettingsMenu" */'../ui/pages/Settings/TimeSettingsMenu'));
const WebSettingsMenu = React.lazy(() => import(/* webpackChunkName: "WebSettingsMenu" */'../ui/pages/Settings/WebSettingsMenu'));
const CustomFieldsSettingsPage = React.lazy(() => import(/* webpackChunkName: "CustomFieldsSettingsPage" */'../ui/pages/Settings/CustomFieldsSettingsPage'));
const ExtraSettingsMenu = React.lazy(() => import(/* webpackChunkName: "ExtraSettingsMenu" */'../ui/pages/Settings/ExtraSettingsMenu'));
const PersonalSettingsMenu = React.lazy(() => import(/* webpackChunkName: "PersonalSettingsMenu" */'../ui/pages/Settings/PersonalSettingsMenu'));
const PublicManageEmailPreferences = React.lazy(() => import(/* webpackChunkName: "PersonalSettingsMenu" */'../ui/pages/Settings/PersonalSettingsMenuEmailPublicManagement'));
const DashboardView = React.lazy(() => import(/* webpackChunkName: "Dashboard" */'../ui/pages/Dashboard/Dashboard'));

const SchedulingTeamView = React.lazy(() => import(/* webpackChunkName: "SchedulingTeamView" */'../ui/pages/Scheduling/SchedulingTeamView'));
const AuditTrailSearchDashboard = React.lazy(() => import(/* webpackChunkName: "AuditTrailSearchDashboard" */'../ui/pages/AuditTrail/AuditTrailSearchDashboard'));
const LocationSettingsMenu = React.lazy(() => import(/* webpackChunkName: "LocationSettingsMenu" */'../ui/pages/Settings/LocationSettingsMenu'));
const PtoSettingsMenu = React.lazy(() => import(/* webpackChunkName: "PtoSettingsMenu" */'../ui/pages/Settings/PtoSettingsMenu'));

const ActivityViewPage = React.lazy(() => import(/* webpackChunkName: "ActivityViewPage" */'../ui/pages/ActivityView/ActivityViewPage'));
const EmailLogsPage = React.lazy(() => import(/* webpackChunkName: "EmailLogsPage" */'../ui/pages/misc/EmailLogsPage'));


const ClockTest = React.lazy(() => import(/* webpackChunkName: "ClockTest" */'../ui/pages/Starbase'));
const LazyClockTest = () => {
    return <VLazyComponent>
        <ClockTest />
    </VLazyComponent>
}

const BasicDateTest = React.lazy(() => import(/* webpackChunkName: "BasicDateTest" */'../ui/pages/experimental/BasicDateTest'));
const AdvancedSettingsMenu = React.lazy(() => import(/* webpackChunkName: "AdvancedSettingsMenu" */'../ui/pages/Settings/AdvancedSettingsMenu'));
const KioskSettings = React.lazy(() => import(/* webpackChunkName: "KioskSettingsMenu" */'../ui/pages/Settings/KioskSettingsMenu'));

const AlertReports = React.lazy(() => import(/* webpackChunkName: "AlertReports" */'../ui/pages/AlertReports'));
const CompanyBranding = React.lazy(() => import(/* webpackChunkName: "CompanyBranding" */'../ui/pages/Settings/CompanyBranding'));

const WorkerIdBadgeValidation = React.lazy(() => import(/* webpackChunkName: "IdBadgeValidation" */'../ui/pages/EmployeeIdBadge/WorkerIdBadgeValidation'));
const WorkerIdBadge = React.lazy(() => import(/* webpackChunkName: "WorkerIdBadge" */'../ui/pages/EmployeeIdBadge/IdBadgeDisplay'));
const WorkerIdBadgeSettings = React.lazy(() => import(/* webpackChunkName: "WorkerIdBadgeSettings" */'../ui/pages/EmployeeIdBadge/IdBadgeConfig'));
const WorkerIdBadgeReports = React.lazy(() => import(/* webpackChunkName: "WorkerIdBadgeReports" */'../ui/pages/EmployeeIdBadge/WorkerIdBadgeReports'));

const FacialRecogitionSettings = React.lazy(() => import(/* webpackChunkName: "FacialRecognitionSettings" */'../ui/pages/Settings/FacialRecognitionSettings'));
const SystemLogsPage = React.lazy(() => import(/* webpackChunkName: "SystemLogsPage" */'../ui/pages/ReportPage/SystemLogsPage'));

const IntuitOpenAuthID = React.lazy(() => import(/* webpackChunkName: "IntuitOpenAuthID" */'../ui/pages/Intuit/IntuitOpenAuthID'));
const AuthenticatedIntuit = React.lazy(() => import(/* webpackChunkName: "AuthenticatedIntuit" */'../ui/pages/Intuit/AuthenticatedIntuit'));

import ClockInOutPage from '../ui/pages/PunchClock';
import { VeriClockApiProvider } from '../../../sharedReact/src/hooks/ApiProvider'
import { ApiUploader, VeriClockApiContextType } from "../../../sharedReact/src/hooks/ApiProviderCommon";

//Todo: look at formatjs:
//https://formatjs.io/docs/intl
//
// seems to be public tooling for localizing
// generating language files from defaults, etc... 

// type VeriClockAvailableLangauges = 'en'|'fr'|'es';
// type VeriClockLocalization = {
//     lang: VeriClockAvailableLangauges
//     changeLocalization: (language:VeriClockAvailableLangauges) => void
// }
// const defaultLocalizationContext:VeriClockLocalization = {
//     lang: 'en',
//     changeLocalization: (language:VeriClockAvailableLangauges) => {}
// }
// const VeriClockLocalizationContext = React.createContext(defaultLocalizationContext);
// const OurAppWrapper:React.FC = (props) => {

//     const [localization, setLocalization] = React.useState({defaultLocalizationContext});
//     return <VeriClockLocalizationContext.Provider value={localization}>
//         {props.children}
//     </VeriClockLocalizationContext.Provider>
// }

export function NotFound()
{
    //todo - fire off a client-side error
    const location = useLocation();
    return <div className="alert alert-danger">
        <b>Doh! We're sorry, nothing found at path:</b><br/><br/>
        <b>{location.pathname}</b><br/><br/>
        Please refresh your browser and try again. Contact support if this problem persists.
    </div>
}

function LazyContactPage()
{
    return <VLazyComponent>
        <ContactsPage />
    </VLazyComponent>
}


function WebAppRouter({locationIndex=0}:{
    locationIndex?:number
})
{
    //hook into webrouter to send url changes if userpilot has been loaded by the external framework
    // useUserPilotHackaroo();
    
    // let location = useLocation<undefined|{background: Location[]}>(); //state is only defined if we come in via a navigation link passing state
    let location = useLocation(); //state is only defined if we come in via a navigation link passing statusToFetch

    let background = location.state && location.state.background;

    //route oldest to newest
    let locationToRoute = location;
    let moreToRoute = false;
    if(background && locationIndex < background.length)
    {
        locationToRoute = background[locationIndex];
        moreToRoute = true;
    }
    
    //note - could use the index/outlet stuff to render the main menu once fully reactified... 
    //location or match against shortlist should provide sufficient logic for active menu
    //or the style callback for that on NavLink would be fine too
    return (
    <>    
        <Routes 
            location={locationToRoute}>
                <Route path="/members/dashboard" element={<VLazyComponent><DashboardView /></VLazyComponent>} />                
                <Route path="/members/support/emailLogs" element={<VLazyComponent><EmailLogsPage /></VLazyComponent>} />

                <Route path="/members/employeeMessaging/*">
                    <Route path="notifications/*" element={<VLazyComponent><NotificationsPage /></VLazyComponent>} />                                    
                    <Route path="broadcast/email" element={<LazyEmailBroadcast />} />                    
                    {/* catchall - re-route to notifications/ */}
                    <Route path="*" element={<Navigate to="/members/employeeMessaging/notifications" replace />} />                    
                </Route>
                
                <Route path="/members/employees/*" element={<VLazyComponent><EmployeesMenuTab pageType="employees"/></VLazyComponent>} />
                <Route path="/members/groups/*" element={<VLazyComponent><EmployeesMenuTab pageType="groups" /></VLazyComponent>} />

                <Route path="/members/jobs/*" element={<JobMenuTab />} />
                <Route path="/members/serviceItems/*" element={<VLazyComponent><ServiceItemSettingsMenu /></VLazyComponent>} />
                <Route path="/members/payrollItems/*" element={<VLazyComponent><PayrollItemSettingsMenu /></VLazyComponent>} />               


{/* Router upgrade todo */}
                <Route path={"/members/pto/*"} element={<VLazyComponent><PtoMenuTab /></VLazyComponent>} />

{/* Settings | Scheduling */}                

                <Route path="/members/settings/scheduling/*" element={<VLazyComponent><SchedulingSettingsPage /></VLazyComponent>} />

{/* Settings | Overtime */}

                <Route path={"/members/settings/overtime"} element={<VLazyComponent><TimeClassifierSettingsPage /></VLazyComponent>} />
                <Route path={"/members/settings/timeClassifier"} element={<VLazyComponent><TimeClassifierSettingsPage /></VLazyComponent>} />
                <Route path="/members/settings/overtimeTest" element={<VLazyComponent><OvertimeTestPage /></VLazyComponent>} />

                <Route path="/members/settings/time/*" element={<VLazyComponent><TimeSettingsMenu /></VLazyComponent>} />
                <Route path="/members/settings/payrollForm" element={<VLazyComponent><PayrollSettingsMenu /></VLazyComponent>} />
                <Route path="/members/settings/timeAdjustment/rounding" element={<VLazyComponent><TimeAdjustmentSettingsMenu type="rounding"/></VLazyComponent>} />
                <Route path="/members/settings/timeAdjustment/deductions" element={<VLazyComponent><TimeAdjustmentSettingsMenu type="deductions"/></VLazyComponent>} />
                <Route path="/members/settings/timeAdjustment/minimums" element={<VLazyComponent><TimeAdjustmentSettingsMenu type="minimums"/></VLazyComponent>} />
                <Route path="/members/settings/webForm" element={<VLazyComponent><WebSettingsMenu /></VLazyComponent>} />
                <Route path="/members/settings/customfields/*" element={<VLazyComponent><CustomFieldsSettingsPage /></VLazyComponent>} />
                <Route path="/members/settings/permissions" element={<VLazyComponent><ExtraSettingsMenu /></VLazyComponent>} />
                <Route path="/members/settings/personalForm/*" element={<VLazyComponent><PersonalSettingsMenu /></VLazyComponent>} />
                <Route path="/members/settings/pto/*" element={<VLazyComponent><PtoSettingsMenu /></VLazyComponent>} />
                <Route path={'/members/settings/advanced/*'} element={<VLazyComponent><AdvancedSettingsMenu /></VLazyComponent>} />
                <Route path={'/members/settings/kiosks/*'} element={<VLazyComponent><KioskSettings /></VLazyComponent>} />
                <Route path='/members/settings/location/*' element={<VLazyComponent><LocationSettingsMenu /></VLazyComponent>} />

{/* ID Badge Related routes */}
                <Route path='/members/settings/workerIdBadge/*' element={<VLazyComponent><WorkerIdBadgeSettings /></VLazyComponent>} />            
                <Route path='/members/workerIdBadge/*' element={<VLazyComponent><WorkerIdBadge /></VLazyComponent>} />
                <Route path='/members/settings/companyBranding' element={<VLazyComponent><CompanyBranding /></VLazyComponent>} />
                <Route path='/members/settings/facialRecognition' element={<VLazyComponent><FacialRecogitionSettings /></VLazyComponent>} />
{/* Scheduling */}
                <Route path="/members/scheduling/*" element={<VLazyComponent><SchedulingTeamView /></VLazyComponent>} />

                <Route path="/members/reports2/scheduledReports" element={<VLazyComponent><ScheduledReportsList /></VLazyComponent>} />
                <Route path="/members/reports2/customReports/*" element={<VLazyComponent><CustomReportsList /></VLazyComponent>} />
                <Route path="/members/reports2/AlertReports/*" element={<VLazyComponent><AlertReports /></VLazyComponent>} /> 
                <Route path="/members/reports2/systemlogsnew/*" element={<VLazyComponent><SystemLogsPage /></VLazyComponent>} /> 
                <Route path="/members/reports2/workerIdBadgeReports/*" element={<VLazyComponent><WorkerIdBadgeReports /></VLazyComponent>} />
                
{/* Support / help related routes */}
                <Route path="/members/support/auditTrail" element={<VLazyComponent><AuditTrailSearchDashboard /></VLazyComponent>} />

{/* Contacts - thirdpartycontact  - cannot route direct until the route is reactified on the direct load (php)*/}
                <Route path="/members/contacts/*" element={<LazyContactPage />} />
                <Route path="/members/clockinout/*" element={<ClockInOutPage />} />
                <Route path="/members/starbase/*" element={<LazyClockTest />} />
                
                <Route path="/BasicDateTest" element={<VLazyComponent><BasicDateTest /></VLazyComponent>} />
                <Route path="/members/activity/*" element={<VLazyComponent><ActivityViewPage /></VLazyComponent> }/>

                <Route path="/v" element={<VLazyComponent><WorkerIdBadgeValidation /></VLazyComponent>} />
                <Route path="/intuit/*" element={<VLazyComponent><IntuitOpenAuthID /></VLazyComponent>} />

                <Route path="/members/welcome/*" element={<VLazyComponent><NewAccountWelcome /></VLazyComponent>} />
                <Route path="/members/intuit/*" element={<VLazyComponent><AuthenticatedIntuit /></VLazyComponent>} />
                <Route path="/login/*" element={<PublicLoginForm />} />
                <Route path="/email/manage" element={<VLazyComponent><PublicManageEmailPreferences /></VLazyComponent>} />
                {/* <Route path="/createAccount/*" element={<CreateAccountForm />} /> */}

{/* 404 - fall through, nothing found */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        {moreToRoute && <WebAppRouter locationIndex={locationIndex+1} /> }
    </>
    );
}

const commonStyle:CSSProperties = {
    position:"fixed",
    bottom: "20px",
    left: "20px",
    backgroundColor: "#AAA",
    border: "solid 3px #888",
    borderRadius: "3x",
    fontFamily: "monospace",
    overflow:"hidden",
}
const openStyle:CSSProperties = {
    width:"100%",
    height: "33%",
}
const closedStyle:CSSProperties = {
    width:"40px",
    height: "40px",
}

///@ts-ignore
function DebugHover()
{
    // const v = useV();
    // const fullName = v.getUser().fullName;
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(old => !old);

    
    if(!isOpen)
    {
        const dbgStyle = {...commonStyle, ...closedStyle}; 
        return <div style={dbgStyle} onClick={toggleOpen}><span className="glyph-icon glyphicon-wrench"></span></div>
    }
    
    const dbgStyle = {...commonStyle, ...openStyle};
    return <div style={dbgStyle}>
        <button onClick={toggleOpen}>Close</button>
        <div style={{fontFamily:"monospace"}}>
            dbg console messages here
        </div>
    </div>
}


//testing the multi-tab/window broadcast shared cache - worked...neato.
// import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental'

const getDefaultApiConnectionSettings = () => {
    return {
        label: "Production",
        url: '',
    }
}

const apiUploader:ApiUploader = (params) => {
    throw new Error('Not implemented on web yet')
}
const makeApi:VeriClockApiContextType['makeApi'] = () => {
    throw new Error('Not implemented on web yet')
}

const queryClient = new QueryClient();
const OurApp:React.FC<PropsWithChildren> = ({children}) => {
    // broadcastQueryClient({
    //     queryClient,
    //     broadcastChannel: 'veribort',
    // });
     
    return(        
    <TranslationProvider>
        <QueryClientProvider client={queryClient}>
            <VeriClockApiProvider makeApi={makeApi} api={BrowserApiClient} apiUploader={apiUploader} getDefaultApiConnectionSettings={getDefaultApiConnectionSettings}>
                    <Router>
                        {/* beware - scheudling delete shift used the dialogs and at some point  */}
                        <AppContextProvider>
                            <DialogProvider>
                                <VPortalProvider>
                                    <WebAppRouter />        
                                </VPortalProvider>
                                {/* <DebugHover /> */}

                            </DialogProvider>
                        </AppContextProvider>
                    </Router>  
            {/* </SchedulingContextProvider> */}
            </VeriClockApiProvider> 
        </QueryClientProvider>
    </TranslationProvider>);
}

type VPortalProviderContextType = {
    mountNode: (node:ReactNode) => void
    removeNode: (node:ReactNode) => void
}
const VPortalProviderContext = React.createContext<VPortalProviderContextType|null>(null);

export const VPortalProvider:React.FC<PropsWithChildren> = ({children}) => {

    const [nodes, setNodes] = useState<ReactNode[]>([]);

    const mountNode = useCallback((node:ReactNode) => {
        setNodes(old => {
            return [...old, node];
        })
    },[]);

    const removeNode = useCallback((node:ReactNode) => {
        setNodes(old => {
            const indexOf = old.findIndex(n => n === node);
            if(indexOf >= 0)
            {
                const newList = [...old];
                newList.splice(indexOf,1);
                return newList;
            }
            return old; //guess it couldn't be found - just continue
        })
    },[]);

    const nodeJsx  = useMemo(() => {
        const jsxList = nodes.map(n => {
            return n;
        });
        return jsxList;
    },[nodes]);

    const ctx = useMemo(() => {
        return {
            mountNode,
            removeNode
        }
    },[removeNode,mountNode]);

    return <VPortalProviderContext.Provider value={ctx}>
        {children}
        {nodeJsx}
    </VPortalProviderContext.Provider> 
}

export const VPortal:React.FC<PropsWithChildren> = ({children}) => {
    const ctx = useContext(VPortalProviderContext);
    if(!ctx)
    {
        throw new Error('VPortal must be used within a PortalProvider');
    }
    const { mountNode, removeNode } = ctx;
    useEffect(() => {
        mountNode(children);
        return () => {
            removeNode(children);
        }
    },[children, mountNode, removeNode]);
    
    return null;

}

import { createRoot } from 'react-dom/client';
import _ from 'lodash';
const container = document.getElementById('reactMount');
if(container) 
{
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<OurApp />);
}