import React from 'react';
import {
    Routes,
    Route,
    useLocation,
    Navigate
  } from "react-router-dom";

import { VLazyComponent } from '../../../ui/components/core/VLazyComponent';
import JobInfo from './JobInfo';
import { TabMenu, TabItem } from '../../components/core/menus/TabNavigation';
import { usePermissions } from '../../../hooks/V';

const JobImport = React.lazy(() => import(/* webpackChunkName: "JobBatchImport" */'../ImportExport/Jobs/JobBatchImport'));
const JobExport = React.lazy(() => import(/* webpackChunkName: "JobExport" */'../ImportExport/Jobs/JobExport'));
const JobsList = React.lazy(() => import(/* webpackChunkName: "JobsList" */'./JobsList'));
const JobEditForm = React.lazy(() => import(/* webpackChunkName: "JobEditForm" */'./JobEditForm'));
const JobRulesForm = React.lazy(() => import(/* webpackChunkName: "JobRulesForm" */'./JobRulesForm'));
const JobImportLazy = () => {
    return <VLazyComponent>
        <JobImport />
    </VLazyComponent>
}
const JobExportLazy = () => {
    return <VLazyComponent>
        <JobExport />
    </VLazyComponent>    
}
const ActiveJobs = () => {
    return <VLazyComponent>
        <JobsList status="active" />
    </VLazyComponent>    
}
const InactiveJobs = () => {
    return <VLazyComponent>
        <JobsList status="inactive" />
    </VLazyComponent>    
}
const DeletedJobs = () => {
    return <VLazyComponent>
        <JobsList status="deleted" />
    </VLazyComponent>    
}
const AddEditJob = () => {
    return <VLazyComponent>
        <JobEditForm />
    </VLazyComponent>    
}

const JobRulesLazy = () => {
    return <VLazyComponent>
        <div className="centerPage">
            <JobRulesForm />
        </div>
</VLazyComponent>
}

const baseUrl = '/members/jobs';

function JobMenuTab()
{
    //from react-router example:
    //https://reactrouter.com/web/example/modal-gallery
    //state is passed in from the job edit button - but state is missing if navigated directly
    //allowing continued display of the same list if launched from it, while switching the url
    // let location = useLocation<undefined|{background: Location}>(); //state is only defined if we come in via a navigation link passing state
    const permissions = usePermissions();
    const canEdit = permissions.hasPermission('can_edit_jobs');
    const canImport = permissions.hasPermission('can_import_jobs');
    const canExport = permissions.hasPermission('can_export_jobs');
    const canViewRules = permissions.hasPermission('can_edit_job_rules');
    // const location = useLocation<undefined|{background:Location[]}>();
    const location = useLocation();
    let nextBackground = [location]
    if(location.state?.background)
        nextBackground = location.state?.background.concat(location);

    //is there a background ?  If so, don't render the menu section, just route
    let background = location.state && location.state.background;

    return <>
        {!background && <div className="centerPage"><TabMenu>
            <TabItem to={baseUrl + '/active'}>Active</TabItem>
            <TabItem to={baseUrl + '/inactive'}>Inactive</TabItem>
            <TabItem to={baseUrl + '/deleted'}>Deleted</TabItem>
            {canEdit && <TabItem to={baseUrl + '/add'} state={{ background: nextBackground }}>Add Job</TabItem>}
            {canImport && <TabItem to={baseUrl + '/import'}>Import</TabItem>}
            {canExport && <TabItem to={baseUrl + '/export'}>Export</TabItem>}
            {canViewRules &&
                <TabItem to={baseUrl + '/rules'}>Rules</TabItem>}
        </TabMenu></div>}
        <Routes>
            <Route path='active' element={<ActiveJobs/>} />
            <Route path='inactive' element={<InactiveJobs/>} />
            <Route path='deleted' element={<DeletedJobs/>} />
            {canEdit && <Route path='add' element={<AddEditJob/>} />}
            <Route path='info/:jobGuid' element={<JobInfo/>} />
            {canEdit && <Route path='edit/:jobGuid' element={<AddEditJob/>} />}
            {canImport && <Route path='import' element={<JobImportLazy/>} />}
            {canExport && <Route path='export' element={<JobExportLazy/>} />}
            {canViewRules && <Route path='rules' element={<JobRulesLazy/>} />}

            <Route path="*" element={<Navigate to='active' replace />} />
        </Routes>
    </>
}

export default JobMenuTab;